<template>
  <blank-container
    title="Подразделение"
    @add-btn="$router.push({ name: 'department-create' })"
  >
    <a-table
      :columns="columns"
      :data-source="$store.getters['department/list']"
      :loading="loading"
      :pagination="{ defaultPageSize: 10 }"
      :row-key="(record) => record.id"
      class="department-table"
    >
      <template slot="department_name" slot-scope="item">
        <a-row align="middle" type="flex">
          {{ item.title || "Не указано" }}
        </a-row>
      </template>

      <template slot="lang_title" slot-scope="item">
        <a-row align="middle" type="flex">
          {{ (item && item.boss && item.boss.full_name) || "Не указано" }}
        </a-row>
      </template>

      <template slot="region" slot-scope="item">
        <a-row align="middle" type="flex">
          {{ (item && item.region && item.region.title) || "Не указано" }}
        </a-row>
      </template>

      <template slot="operation" slot-scope="item">
        <a-icon
          class="action-btns"
          type="edit"
          @click="
            $router.push({ name: 'department-detail', params: { id: item.id } })
          "
        />
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-icon class="action-btns" style="margin: 0 10px" type="delete" />
        </a-popconfirm>
      </template>
    </a-table>
  </blank-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          title: this.$t("TableTitle"),
          key: "department_name",
          scopedSlots: { customRender: "department_name" },
          width: "40%"
        },
        {
          title: "Ф.И.О",
          key: "title",
          scopedSlots: { customRender: "lang_title" },
          width: "25%"
        },
        {
          title: "Region",
          key: "region",
          scopedSlots: { customRender: "region" }
          // width: "25%"
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          scopedSlots: { customRender: "operation" },
          width: "10%"
        }
      ]
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    },
    "$i18n.locale": {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        await this.$store.dispatch("department/fetch")
      } catch (error) {
        console.error(error)
        this.loading = false
      }

      this.loading = false
    },

    async removeItem(id) {
      //todo
      try {
        await this.$store.dispatch("department/removeItemAction", id)
        // console.log("removeItem", res)
        await this.fetchData()
      } catch (e) {
        console.error("removeItem", e)
      }
    }
  }
}
</script>
